<template>
  <input
    :type="type"
    :min="min"
    class="form-control"
    step="any"
    :value="isFocused ? originalValue : formattedValue"
    @focus="focusEvent"
    @blur="blurEvent($event)"
    @input="inputEvent($event)"
  />
</template>
<script>
export default {
  name: 'CurrencyInput',
  model: {
    prop: 'value',
  },
  props: {
    value: { type: [String, Number], default: undefined },
    min: { type: [String, Number], default: '0' },
    changeOnFocusout: { type: [Boolean], default: false },
  },
  data() {
    return {
      type: 'text',
      isFocused: false,
    };
  },
  computed: {
    originalValue() {
      return this.value;
    },
    formattedValue() {
      return this.spaceNumber(this.originalValue);
    },
  },
  methods: {
    focusEvent() {
      this.isFocused = true;
      this.type = 'number'; // toggle to number input
    },
    blurEvent(event) {
      this.isFocused = false;
      // '' || null -> '';
      // 234,23 || 234.23 -> 234.23;
      // 234 -> 234
      const value =
        event.target.value !== '' && event.target.value !== null
          ? /(,|\.)/.test(event.target.value)
            ? (+event.target.value).toFixed(2)
            : +event.target.value
          : '';
      if (this.changeOnFocusout) {
        this.$emit('change', value);
      } else {
        this.$emit('input', value);
      }
      this.$emit('setLastUsedInput', this.$attrs.id);
      this.type = 'text'; // toggle back to masked number view
    },
    inputEvent(event) {
      const value = event.target.value ?? '';
      this.$emit('input', value);
    },
    spaceNumber(inputNumber) {
      return String(inputNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
};
</script>
