<template>
  <!-- DOUBLE SPOTS MODAL -->
  <b-modal
    id="modal-double-spots"
    ref="modal-double-spots"
    :title="type === 'add' ? $t('table.addDoubleSpot') : $t('table.editDoubleSpot')"
    :ok-disabled="$v.$invalid"
    :ok-title="type === 'add' ? $t('table.add') : $t('table.save')"
    :cancel-title="$t('table.cancel')"
    @hidden="clearFields"
    @ok="sendDoubleSpot"
  >
    <form @submit.stop.prevent="checkIfValidAddThenEnter">
      <input type="submit" value="Submit" class="hidden-submit" />
      <b-form-group :label="$t('booking.selectCommercial')">
        <MultiSelect
          v-model.trim="commercialFirst"
          label="name"
          track-by="id"
          :options="commercials || []"
          :placeholder="$t('booking.selectCommercial')"
          :disabled="type === 'edit'"
          data-automatic="ms-first-commercial"
        ></MultiSelect>
      </b-form-group>
      <b-form-group :label="$t('table.distance')" label-for="input-distance-ds">
        <b-form-input id="input-distance-ds" v-model.number="distance" type="number" min="0"></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('booking.selectCommercial')">
        <MultiSelect
          v-model.trim="commercialSecond"
          label="name"
          track-by="id"
          :options="commercialSecondList"
          :placeholder="$t('booking.selectCommercial')"
          :disabled="!commercialFirst || type === 'edit'"
          data-automatic="ms-second-commercial"
        ></MultiSelect>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import MultiSelect from '@/components/MultiSelect';
import { required, minValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalDoubleSpot',
  components: { MultiSelect },
  props: {
    type: {
      type: String,
      default: undefined,
    },
    commercials: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      distance: 0,
      commercialFirst: null,
      commercialSecond: null,
      commercialSecondList: [],
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),
  },
  validations() {
    return {
      distance: { required, minValue: minValue(0) },
      commercialFirst: { required },
      commercialSecond: { required },
    };
  },
  watch: {
    commercials() {
      if (this.type === 'edit' && this.commercials) {
        this.commercialFirst = this.commercials[0];
        this.commercialSecond = this.commercials[1];
        this.distance = this.commercials[0].distance;
      }
    },
    commercialFirst() {
      if (this.commercialFirst) {
        this.commercialSecondList = this.commercials.filter((el) => el.id !== this.commercialFirst.id);
        if (this.commercialSecond && this.commercialFirst.id === this.commercialSecond.id) this.commercialSecond = null;
      }
    },
  },

  methods: {
    clearFields() {
      this.distance = 0;
      this.commercialFirst = null;
      this.commercialSecond = null;
      this.commercialSecondList = [];
    },

    hideModalDoubleSpot() {
      this.$bvModal.hide('modal-double-spots');
    },

    checkIfValidAddThenEnter() {
      if (!this.$v.$invalid) this.sendDoubleSpot();
    },

    sendDoubleSpot() {
      const doubleSpot = [];
      if (this.type === 'add') {
        const ds_id = Date.parse(new Date());
        doubleSpot.push(
          {
            ...this.commercialFirst,
            double_spot_id: ds_id,
            distance: this.distance,
          },
          {
            ...this.commercialSecond,
            double_spot_id: ds_id,
            distance: this.distance,
          }
        );
        this.$emit('doubleSpot', doubleSpot, 'add');
      } else {
        //For DS edit
        doubleSpot.push(
          {
            ...this.commercialFirst,
            double_spot_id: this.commercialFirst.double_spot_id,
            distance: this.distance,
          },
          {
            ...this.commercialSecond,
            double_spot_id: this.commercialSecond.double_spot_id,
            distance: this.distance,
          }
        );
        this.$emit('doubleSpot', doubleSpot, 'edit');
      }
      this.hideModalDoubleSpot();
    },
  },
};
</script>

<style lang="sass"></style>
