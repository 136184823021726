import { sec2time } from '@/utils/timeConverters';

export default {
  methods: {
    getYear(day) {
      return day.substr(0, 4);
    },
    getStartInterval(channel, year) {
      if (year != '' && channel.channel_settings[year]) {
        return channel.channel_settings[year].start_interval;
      }
      return process.env.VUE_APP_BROADCAST_START_TIME ? +process.env.VUE_APP_BROADCAST_START_TIME : 6 * 60 * 60;
    },

    startTimeTemplatesFiltered(start, end) {
      const startTimePresets = [];
      for (let i = start; i < end; i += 5 * 60) {
        startTimePresets.push(sec2time(i));
      }
      return startTimePresets;
    },
  },
};
