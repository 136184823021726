<template>
  <div>
    <b-modal id="modal-inspect-commercial" ref="modal-inspect-commercial" size="xl" scrollable>
      <template #modal-header>
        <h4>{{ $t('table.inspect') }}</h4>
        <b-icon font-scale="1.75" icon="x" style="cursor: pointer" @click="hideModalProject"></b-icon>
      </template>

      <template #default>
        <div>
          <div class="d-flex align-items-center gap-3 mb-3">
            <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" @pageClick="pageClick" />
            <search-dropdown v-model="selectedPerPage" class="mr-auto" @onSelect="pageClick(1)" />
            <search-input v-model="searchElements" :show-selector="true" :selected-search-type.sync="searchType" @searchTable="pageClick(1)" />
          </div>
          <!--TABLE-->
          <div class="table-responsive vocabulary-table" style="max-height: calc(100vh - 275px)">
            <table class="table table-hover" style="white-space: nowrap">
              <thead>
                <th scope="col" style="min-width: 50px">
                  <sort-arrows :sort-string="sortString" :column="'id,'" @click="sortTable('id,', $event)" />
                  ID
                </th>
                <th scope="col" style="min-width: 400px">
                  <div class="resizable-div__name">
                    <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                    {{ $t('table.name') }}
                  </div>
                </th>
                <th scope="col">{{ $t('table.version') }}</th>
                <th scope="col" style="min-width: 150px">
                  <sort-arrows :sort-string="sortString" :column="'status,'" @click="sortTable('status,', $event)" />
                  {{ $t('table.status') }}
                </th>
                <th scope="col">{{ $t('table.duration') }}</th>
                <th scope="col">{{ $t('table.brand') }}</th>
                <th scope="col">{{ $t('table.co_brand') }}</th>
                <th scope="col">URL</th>
                <th scope="col" style="min-width: 150px">
                  <sort-arrows :sort-string="sortString" :column="'legal_before_at,'" @click="sortTable('legal_before_at,', $event)" />
                  {{ $t('table.date') }}
                </th>
                <th scope="col" style="min-width: 100px">
                  <sort-arrows :sort-string="sortString" :column="'is_visible,'" @click="sortTable('is_visible,', $event)" />
                  {{ $t('table.isVisible') }}
                </th>
              </thead>
              <tbody>
                <tr v-if="commercialsStatus !== 'success'">
                  <td colspan="10"><SpinnerLoader :loading="commercialsStatus" /></td>
                </tr>
                <tr
                  v-for="(row, index) in tableData"
                  :key="index"
                  class="cursor-pointer"
                  @contextmenu.prevent="[(isOpenModalCommercial = 'modal-commercial'), (modalNameCommercial = 'edit'), (currentCommercial = row)]"
                >
                  <td>
                    {{ row.id }}
                  </td>
                  <td class="overflow-hidden text-overflow-ellipsis" style="max-width: 400px">
                    <button
                      type="button"
                      class="btn-icon d-inline-block"
                      :title="canEdit ? $t('table.edit') : $t('table.details')"
                      @click="[(isOpenModalCommercial = 'modal-commercial'), (modalNameCommercial = 'edit'), (currentCommercial = row)]"
                    >
                      <b-icon icon="pencil"></b-icon>
                    </button>
                    <button
                      v-if="canDelete"
                      type="button"
                      class="btn-icon d-inline-block"
                      :title="$t('table.delete')"
                      @click="[(isOpenModalCommercial = 'modal-commercial'), (modalNameCommercial = 'delete'), (currentCommercial = row)]"
                    >
                      <b-icon icon="trash"></b-icon>
                    </button>
                    {{ row.name }}
                  </td>
                  <td>
                    {{ row.commercial_version_type ? row.commercial_version_type.name : '' }}
                  </td>
                  <td>
                    {{ row.status === 0 ? $t('table.new') : $t('table.onChannel') }}
                  </td>
                  <td>
                    {{ row.duration }}
                  </td>
                  <td>
                    {{ row.brand ? row.brand.name : '' }}
                  </td>
                  <td>
                    {{ row.co_brand ? row.co_brand.name : '' }}
                  </td>
                  <td>
                    <template v-if="row.url">
                      <a :href="row.url" target="_blank" rel="noopener noreferrer">{{ $t('table.link') }}</a>
                    </template>
                  </td>
                  <td>
                    <template v-if="row.legal_before_at">
                      {{ row.legal_before_at | convertDate }}
                    </template>
                  </td>
                  <td>
                    <b-form-checkbox
                      :id="`checkbox-1-${index}`"
                      :checked="row.is_visible"
                      :name="`checkbox-1-${index}`"
                      :disabled="!canEdit || !canUpdateIsVisible"
                      @change="toggleVisibility(row)"
                    >
                    </b-form-checkbox>
                  </td>
                </tr>
                <tr v-if="commercialsStatus === 'success' && !tableData.length">
                  <td colspan="10">
                    <div class="d-flex w-100 justify-content-center">
                      {{ $t('table.noContent') }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--TABLE-->

          <ModalCommercial
            :commercial="currentCommercial"
            :modal-name="modalNameCommercial"
            :is-open-modal="isOpenModalCommercial"
            :brand-id="brandId"
            :is-from-media-plan="true"
            :commercial-type-id="commercialTypeId"
            :can-edit="canEdit"
            :project-advertiser-id="projectAdvertiserId"
            @update="updateCommercials"
            @isOpenModal="isOpenModalCommercial = null"
          />
        </div>
      </template>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            v-if="$checkPermissions('commercial.create')"
            @click="[(isOpenModalCommercial = 'modal-project'), (modalNameCommercial = 'add'), (currentCommercial = {})]"
          >
            {{ $t('table.addNewCommercials') }}
          </b-button>
          <b-button variant="outline-danger mr-3" @click="hideModalProject">
            {{ $t('table.close') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
//import MultiSelect from "@/components/MultiSelect";
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import convertDate from '@/filters/convertDate';
import ModalCommercial from '@/components/Commercials/ModalCommercial';
import errorsHandler from '@/utils/errorsHandler';
import SearchDropdown from '@/components/SearchDropdown';
import SearchInput from '@/components/SearchInput';
import SortArrows from '@/components/SortArrows';
import sortTableMixin from '@/mixins/sortTable';
import PaginateWrapper from '@/components/PaginateWrapper';

export default {
  name: 'ModalInspectCommercial',
  components: { SpinnerLoader, ModalCommercial, SearchDropdown, SearchInput, SortArrows, PaginateWrapper },
  filters: {
    convertDate,
  },
  mixins: [sortTableMixin],
  props: {
    isOpenModal: {
      required: false,
      type: Boolean,
    },
    modalName: {
      type: String,
      default: undefined,
    },
    brandId: {
      type: Number,
      default: undefined,
    },
    commercialTypeId: {
      type: Number,
      default: undefined,
    },
    agencyId: {
      type: Number,
      default: undefined,
    },
    projectAdvertiserId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      tableData: [],
      currentCommercial: null,
      modalNameCommercial: null,
      isOpenModalCommercial: null,

      paginationData: '',
      searchElements: '',
      filterColumn: 'name',
      filterPage: 1,
      selectedPerPage: '100',
      searchType: 'name',
      sortString: '-id,',
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      commercials: 'getCommercialsList',
      commercialsStatus: 'getCommercialsStatus',
      advertisersList: 'getAdvertisersList',
    }),
    canEdit: function () {
      return this.$checkPermissions('commercial.update');
    },
    canDelete: function () {
      return this.$checkPermissions('commercial.delete');
    },
    canUpdateIsVisible() {
      return this.$checkPermissions('commercial.update_is_visible');
    },
  },
  watch: {
    isOpenModal() {
      this.showModalProject();
    },
    commercials() {
      this.setTableData();
    },
  },
  mounted() {
    this.setTableData();
  },
  destroyed() {
    this.$store.commit('clearCommercialsList');
  },
  methods: {
    async showModalProject() {
      if (this.isOpenModal) {
        if (this.advertisersList.length < 1 || (this.advertisersList.length > 0 && this.advertisersList[0].agency_id !== this.agencyId)) {
          await this.$store.dispatch('GET_ADVERTISERS', {
            per_page: 1000,
            'filter[agency_id]': this.agencyId,
          });
        }
        this.$refs['modal-inspect-commercial'].show();
        this.$emit('isOpenModal', null);
        this.paramsData();
      }
    },
    hideModalProject() {
      this.$refs['modal-inspect-commercial'].hide();
    },
    hideModalProjectConfirm() {
      this.$refs['modal-inspect-commercial-confirm'].hide();
    },

    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_COMMERCIALS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' && this.searchType === 'name' ? this.searchElements : null,
        'filter[id]': this.searchElements !== '' && this.searchType === 'id' ? this.searchElements : null,
        'filter[brand_id]': this.brandId,
        'filter[commercial_type_id]': this.commercialTypeId,
        sort: this.sortString,
        include: 'commercialVersionType,brand,coBrand,commercialType',
      });
    },

    setTableData() {
      if (this.commercials) {
        this.tableData = this.commercials.data;
        this.paginationData = this.commercials.pagination;
        if (this.filterPage > this.commercials.pagination.last_page) {
          this.filterPage = this.commercials.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    // Toggle commercial visibility
    async toggleVisibility(row) {
      const commercialId = row.id;
      const formData = {
        is_visible: !row.is_visible,
      };
      await this.$store.dispatch('PUT_COMMERCIALS', {
        commercialId,
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.changedCommercialVisibility'),
            text: row.name,
          });
          this.updateCommercials();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
    },

    updateCommercials() {
      this.paramsData();
      this.$emit('updateCommercials');
    },
  },
};
</script>

<style lang="sass">
#modal-inspect-commercial > .modal-dialog
  width: calc(100% - 16px)
  max-width: 1600px
</style>
<style lang="sass" scoped>
table thead
  .resizable-div__name
    overflow: auto
    min-width: 400px

  &:hover .resizable-div__name
    resize: horizontal
</style>
